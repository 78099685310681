.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}

@primary: #1c90ff;
@actionColor: #ff5501;
.load-wrap {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.load-wrap-middle {
    width: 100%;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-wrap-small {
    width: 100%;
    height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}